<template>
  <div class="points">
    <div
      v-for="(_, i) in points"
      :key="i"
      class="point"
      :class="{ selected: index === i }"
      @click="emit('on-point-click', i)"
    />
  </div>
</template>

<script lang="ts" setup>
import { SwiperBinding } from '@/lib/types/models/swiper';

const props = withDefaults(
  defineProps<{
    modelValue?: SwiperBinding;
    current?: number;
    max?: number;
    wholeGroup?: boolean;
  }>(),
  { modelValue: undefined, current: undefined, max: undefined, wholeGroup: false }
);

const emit = defineEmits<(event: 'on-point-click', index: number) => void>();

const pointsCount = computed(() => {
  if (!props.modelValue) {
    return 1;
  }

  return props.modelValue.snapGrid.length;
});

const index = computed(() => {
  if (!props.modelValue) {
    return 0;
  }

  const index = props.modelValue.realIndex;
  return props.wholeGroup ? Math.floor(index / props.modelValue.actualSlidesPerGroup) : index;
});
const points = computed(() => Array(pointsCount.value));
</script>

<style lang="scss" scoped>
@use 'sass:math';
@use '$/colors.scss';

.points {
  gap: 12px;
  justify-content: center;

  .point {
    $size: 10px;

    cursor: pointer;

    width: $size;
    height: $size;

    background-color: white;
    border-radius: math.div($size, 2);

    transition-duration: 0.2s;

    &.selected {
      background-color: colors.$green;
      border: solid 1px white;
    }
  }
}
</style>
